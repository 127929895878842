import Button from "./Button";
const UpgradeButton = ({
  type,
  className,
  onClick,
  cost,
  impact,
  moneyCounter,
  label,
}) => {
  return (
    <div className="col">
      <div>
        <Button
          label={`$${cost} - increases ${type} by ${impact} per click`}
          size="lg"
          variant="dark"
          onClick={() => onClick(impact, cost)}
          className={`w-100 col-3 btn-block ${className}`}
          disabled={moneyCounter - cost < 0}
        />
      </div>
    </div>
  );
};

export default UpgradeButton;
