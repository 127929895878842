import "./App.css";
import Button from "./components/Button";
import { useEffect, useState } from "react";
import UpgradeButton from "./components/UpgradeButton";
import useClock from "./hooks/useClock";

function App() {
  // Setting initual values and creating useStates of all changing constants
  const clock = useClock(0);
  const [itemCounter, setItemCounter] = useState(0);
  const [moneyCounter, setMoneyCounter] = useState(0);
  const [itemClickValue, setItemClickValue] = useState(1);
  const [sellClickValue, setSellClickValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState();
  const [makeItemPerSecond, setMakeItemPerSecond] = useState(5);
  
  useEffect(()=>{
    setItemCounter(itemCounter + makeItemPerSecond);
  },[clock])




  //Logic for the 'Make' button
  const plusCounter = () => {
    setItemCounter(itemCounter + 1 * itemClickValue);
    setErrorMessage(undefined);
  };

  //Logic for the 'Sell' button
  //Logic for only being able to sell the items you have made
  const minusCounter = () => {
    if (itemCounter - sellClickValue >= 0) {
      setItemCounter(itemCounter - 1 * sellClickValue);
      setMoneyCounter(moneyCounter + 1 * sellClickValue);
    } else if (itemCounter - sellClickValue < 0) {
      setItemCounter(itemCounter - itemCounter);
      setMoneyCounter(moneyCounter + itemCounter);
    }
  };

  //upgrade buttons for the two values with modular change amounts
  const changeMakeValue = (changeAmount, cost) => {
    if (moneyCounter - cost >= 0) {
      setItemClickValue(itemClickValue + changeAmount);
      setMoneyCounter(moneyCounter - cost);
    } else if (moneyCounter - cost < 0) {
    }
  };
  const changeSellValue = (changeAmount, cost) => {
    if (moneyCounter - cost >= 0) {
      setSellClickValue(sellClickValue + changeAmount);
      setMoneyCounter(moneyCounter - cost);
    } else if (moneyCounter - cost < 0) {
    }
  };
  
  return (
    <div className="App">
      {errorMessage && (
        <div
          class="alert sticky-top alert-danger d-flex align-items-center"
          role="alert"
        >
          <div>{errorMessage}</div>
        </div>
      )}
      <div className="container text-center">
        {/* New layout (mobile friendly) */}
        {/* Items section */}
        <div className="mb-5 bg-grey p-3">
          <div>
            <p className="display-5 mb-0 user-select-none">
              {itemCounter} items
            </p>
            <p className="mb-0 user-select-none">{itemClickValue} per click</p>
            <p className="mb-2 user-select-none">0 per second</p>
            <Button
              label="Make items"
              size="lg"
              variant="success"
              onClick={plusCounter}
              className="px-5"
            />
          </div>
          {/* money section */}
          <div>
            <p className="display-5 mb-0 user-select-none">${moneyCounter}</p>
            <p className="mb-0 user-select-none">{sellClickValue} per click</p>
            <p className="mb-2 user-select-none">0 per second</p>
            <Button
              label="Sell items"
              size="lg"
              variant="danger"
              onClick={minusCounter}
              disabled={itemCounter <= 0}
              className="px-5"
            />
          </div>
        </div>
        {/* below both sections */}

        <div className="row">
          <UpgradeButton
            onClick={changeSellValue}
            cost={1}
            impact={5}
            moneyCounter={moneyCounter}
            className="mt-5 bg-block"
            type="sell"
          />
          <UpgradeButton
            onClick={changeMakeValue}
            cost={2}
            impact={3.5}
            moneyCounter={moneyCounter}
            className="mt-5 col"
            type="make"
          />
          <UpgradeButton
            onClick={changeMakeValue}
            cost={2}
            impact={5}
            moneyCounter={moneyCounter}
            className="mt-5 col"
            type="make"
            label="hi there im here"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
